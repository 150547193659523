/* General Styling */ 
.HomePage {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 100vh; 
    transition: background 0.5s ease-in-out;
    background: url(../../Images/darkhomepage.jpg);
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
} 

/* Light Mode */
.HomePage.lightMode {
    background: url(../../Images/lighthomepage.jpg);
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typedText {
    font-size: 1.75em;
    color: white;
    text-shadow: 2px 2px 10px white;
}

.typedText.lightMode {
    text-shadow: 2px 2px 5px black;
}

.text-body {
    height: 150px;
    width: 600px;
    padding: 10px;
    text-align: center;
    display: block;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

/* Led Buttons */
.ledButton {
    text-align: center;
    width: fit-content;
    position: relative;
    padding: 10px 30px;
    color: white;
    cursor: pointer;
    font-size: 1.35em;
    text-decoration: none;
    overflow: hidden;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
    background-color: transparent;
    transition: all 0.5s;
}

.ledButton.lightMode {
    text-shadow: 2px 2px 5px black;
}

.ledButton:hover {
    background: #fff;
    color: #000;
    text-shadow: 2px 2px 10px black;
    box-shadow: 1px 1px 15px white;
}

.ledButton span {
    position: absolute;
    display: block;
}

.ledButton span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    animation: animate1 1s linear infinite;
}

@keyframes animate1 {
    0% {
        left: -100%;
    }
    50%, 100% {
        left: 100%;
    }
}

.ledButton span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    animation: animate2 1s linear infinite 0.25s;
}

@keyframes animate2 {
    0% {
        top: -100%;
    }
    50%, 100% {
        top: 100%;
    }
}

.ledButton span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    animation: animate3 1s linear infinite 0.5s;
}

@keyframes animate3 {
    0% {
        right: -100%;
    }
    50%, 100% {
        right: 100%;
    }
}

.ledButton span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    animation: animate4 1s linear infinite 0.75s;
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%, 100% {
        bottom: 100%;
    }
}

.modal-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
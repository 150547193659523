.tabs-container {
    padding: 2.5%;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.tabs .nav-link {
    display: block;
    padding: .5rem 1.5rem;
}

.tabs a {
    color: white;
    text-decoration: none;
    background-color: transparent;
    font-size: 20px;
}

.tabs a:hover {
    color: white;
}

.tabs a:after {
    content: "";
    display: block;
    height: 2px;
    background: white;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.tabs a:hover:after {
    width: 100%;
}

.tabs .nav-link {
    border-bottom: 1px solid white;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    transition: all 0.25s smooth;
}

.tabs .nav-link.active {
    color: white;
    font-weight: bold;
    border: 1px solid white;
    border-bottom: 1px solid transparent;
}

.tab-info-container {
    display: block;
    padding: 20px;
}

.tab-info {
    color: white;
    font-size: 18px;
}

.modal-content {
    background-color: rgb(0, 0, 0, 0.75);
    box-shadow: 2px 2px 10px white;
}

.modal-footer {
    border-top: none;
}

.btnLink {
    color: white;
    font-size: 18px;
    padding: 10px;
    margin-left: 10px;
    border: 1px solid white;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.btnLink:hover {
    color: black;
    background-color: white;
    text-decoration: none;
    box-shadow: 1px 1px 20px white;
}

@media (max-width: 800px) {
    .tabs a {
        font-size: 16px;
    }

    .tab-info {
        font-size: 14px;
    }

    .btnLink {
        color: white;
        font-size: 14px;
        padding: 10px;
        margin-left: 10px;
        border: 1px solid white;
        border-radius: 5px;
        text-decoration: none;
        transition: all 0.3s ease;
    }
}
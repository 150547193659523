* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background: black;
}

::-webkit-scrollbar {
    /* width: 5px;
    background: black; */
    display: none;
}

::-webkit-scrollbar-thumb {
    /* background: white;
    border-radius: 20px; */
    display: none;
}

/* .banner {
    position: relative;
    width: 100%;
    height: 100vh; 
    background: url(../Images/homepage.png);
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner .text {
    position: absolute;
    font-size: 12em;
    color: #fff;
} */

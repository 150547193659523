.card {
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    width: 900px;
    height: 150px;
    padding: 10px 20px;
    transition: all 0.5s ease-in-out;
    height: fit-content;
    text-shadow: 1px 1px 30px black;
    border-radius: 10px;
}

.card:hover {
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 10px black;
}

.card .content {
    padding: 5px;
    color: white;
}

.card .content h2 {
    color: white;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
}

.card .content h2.job-type {
    font-size: 1em;
    right: 0.5em;
    top: 2em;
}

.card:hover .content h2.job-type {
    opacity: 1;
    font-size: 1em;
    transform: translateY(-15px);
}

.job-title {
    font-size: 1.5em;
}

.card .bulletpoints {
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-size: 1.15em;
    margin-top: 0;
}

.company-and-location {
    display: flex;
    float: left;
    font-size: 1.15em;
}

.job-date {
    font-size: 1.15em;
    display: flex;
    float: right;
}

.bulletpoint-text {
    font-size: 0.8em;
}

@media (max-width: 450px) {
    .job-title {
        font-size: 1.25em;
    }

    .card .bulletpoints {
        font-size: 1em;
        padding-top: 10px;
    }

    .company-and-location {
        padding-top: 10px;
        font-size: 1em;
        float: left;
    }
    
    .job-date {
        font-size: 1em;
        float: left;
    }

    .card .content h2.job-type {
        display: none;
    }
}
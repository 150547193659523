.heavenClouds {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    left: 0;
    right: 0;
}

.heavenClouds.top {
    transform: rotateX(-180deg);
}

/* Styling for cloud images */
.heavenClouds img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    animation: animateBottom calc(3s * var(--i)) linear infinite;
    transition: 1s ease-in-out;
}

.heavenClouds.top img {
    animation: animateTop calc(3s * var(--i)) linear infinite;
}

/* Cloud animation keyframe */
@keyframes animateBottom {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    25%, 75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes animateTop {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    25%, 75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

/* Layering css */
.cloud1 {
    --i: 1;
}

.cloud2 {
    --i: 2;
}

.cloud3 {
    --i: 3;
}

.cloud4 {
    --i: 4;
}

.cloud5 {
    --i: 5;
}

.cloud6 {
    --i: 10;
}

.cloud7 {
    --i: 9;
}

.cloud8 {
    --i: 8;
}

.cloud9 {
    --i: 7;
}

.cloud10 {
    --i: 6;
}

.cloud1.darkMode, .cloud2.darkMode, .cloud3.darkMode, .cloud4.darkMode, .cloud5.darkMode,
.cloud6.darkMode, .cloud7.darkMode, .cloud8.darkMode, .cloud9.darkMode, .cloud10.darkMode {
    filter: sepia(100%) saturate(500%) hue-rotate(120deg) contrast(50%);
}
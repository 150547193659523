/* General Styling */ 
.ExperiencePage {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    min-height: 100vh; 
    transition: background 0.5s ease-in-out;
    height: fit-content;
    background: url(../../Images/darkexperiencepage.png);
    background-size: cover;
    background-position: bottom;
} 

/* Light Mode */
.ExperiencePage.lightMode {
    background: url(../../Images/lightexperiencepage.jpg);
    background-size: cover;
    background-position: bottom;
}

.experiencePageHeaderText {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    text-shadow: 2px 2px 20px black;
}

.card-container {
    padding-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5em;
    padding-left: 100px;
    padding-right: 100px;
}

.card-container:last-child {
    padding-bottom: 10%;
}

@media (max-width: 800px) {
    .card-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}
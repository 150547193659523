/* General Styling */
.ProjectsPage {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    min-height: 100vh; 
    transition: background 0.5s ease-in-out;
    height: fit-content;
    background: url(../../Images/darkprojectspage.jpg);
    background-size: cover;
    background-position: bottom;
}

/* Light Mode */
.ProjectsPage.lightMode {
    background: url(../../Images/lightprojectspage.jpg);
    background-size: cover;
    background-position: bottom;
}

.projectPageHeaderText {
    position: relative;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    text-shadow: 2px 2px 20px black;
}